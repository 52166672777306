import React from 'react'
import PropTypes from 'prop-types'

const PlayBtnSVG = (props) => {
  const { bgColor, fgColor } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='200'
      height='200'
      version='1.1'
      viewBox='0 0 52.917 52.917'>
      <g
        fillOpacity='1'
        strokeDasharray='none'
        strokeLinejoin='miter'
        strokeMiterlimit='4'
        strokeOpacity='1'
        paintOrder='normal'
        transform='translate(0 -244.083)'>
        <circle
          cx='26.458'
          cy='270.542'
          r='25.398'
          fill={bgColor}
          stroke={bgColor}
          strokeLinecap='round'
          strokeWidth='1.27'
          opacity='1'></circle>
        <path
          fill={fgColor}
          stroke={fgColor}
          strokeLinecap='butt'
          strokeWidth='1.746'
          d='M39.63 270.542l-10.679 6.165-10.678 6.165v-24.66l10.678 6.165z'
          opacity='1'></path>
      </g>
    </svg>
  )
}

PlayBtnSVG.propTypes = {
  bgColor: PropTypes.string.isRequired,
  fgColor: PropTypes.string.isRequired,
}

export default PlayBtnSVG
