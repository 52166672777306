import React from 'react'
import PropTypes from 'prop-types'

const BigMenu = (props) => {
  const { nav_links, colors, toggleActive } = props

  return (
    <ul className='navbar_links'>
      {nav_links.map((link, idx) => {
        let class_name = 'navbar_links-link'
        let style = {color: colors.txtColor}
        if (idx === 0) {
          class_name+= ' active-link'
          style.color = colors.bgColor
        }
        return (<li key={link.name.toLowerCase()} style={style} className={`${class_name}`}>
          <a href={link.href} onClick={toggleActive}>{link.name}</a>
        </li>)
      })}
    </ul>
  )
}

BigMenu.propTypes = {
  nav_links: PropTypes.array.isRequired,
  colors: PropTypes.object.isRequired,
  toggleActive: PropTypes.func.isRequired,
}

export default BigMenu
