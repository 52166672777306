import React from 'react'
import PropTypes from 'prop-types'

import { toCamelCase } from '../../../../helpers/songInfoFunctions'

import Audio from './Audio'

const Player = (props) => {
  const { tune } = props

  const album_art_src = `./assets/images/coverArt/${toCamelCase(
    tune.title
  )}Cover-medium.png`

  return (
    <div
      className='player'
      style={{ backgroundColor: `${tune.color_scheme.flexColor2}` }}>
      <div className='player_cover'>
        <img
          src={album_art_src}
          alt={tune.title}
          className='player_cover-img'
        />
      </div>

      <Audio tune={tune} />
    </div>
  )
}

Player.propTypes = {
  tune: PropTypes.object.isRequired,
}

export default Player
