import React from 'react'

import '../../../styles/modules/global/dist/bio.css'

const Bio = (props) => {
  return (
    <div className='bio' id='bio'>
      <div className='bio-img-container'>
        <img
          src='./assets/images/bnAvatar-small.png'
          alt='Boss'
          className='bio-img'
        />
      </div>
      <div className='bio-text'>
        Bossquez Nu&ntilde;ez
        <br />
        <hr id='about-hr' />
        <br />
        Music
        <br />
        Audio Production
        <br />
        Web Development
      </div>
    </div>
  )
}

export default Bio
