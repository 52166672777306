const default_color_theme = {
    bgColor: '#05052C',
    txtColor: '#EEEFC2',
    flexColor1: '#E85851',
    flexColor2: '#07A99A',
    flexColor3: '#7C7C8C',
}

module.exports = {
    default_color_theme
}