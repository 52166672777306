import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const NavMenu = (props) => {
  const { nav_links, colors, handleLinkClick } = props
  return (
    <div
      className='menu closed'
      id='nav-menu'
      style={{ backgroundColor: colors.flexColor1 }}>
      <div className='menu_links'>
        <div className='menu_links_container'>
          {nav_links.map((link, idx) => {
            let class_name = 'menu_links_container-link'
            let style = { color: colors.txtColor }
            if (idx === 0) {
              class_name += ' active-link'
              style.color = colors.bgColor
            }
            return (
              <NavLink
                key={link.name.toLowerCase()}
                to={link.href}
                className={class_name}
                style={style}
                id={`${link.name.toLowerCase()}-link`}
                onClick={handleLinkClick}>
                {link.name}
              </NavLink>
            )
          })}
        </div>
      </div>
    </div>
  )
}

NavMenu.propTypes = {
  nav_links: PropTypes.array.isRequired,
  colors: PropTypes.object.isRequired,
  handleLinkClick: PropTypes.func.isRequired,
}

export default NavMenu
