import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import { getHref, toCamelCase, isOutNow } from '../../../helpers/songInfoFunctions'

const SmallTuneLink = (props) => {
  const { classes, tune, num_of_songs } = props

  const img_src = `${process.env.PUBLIC_URL}/assets/images/coverArt/${toCamelCase(
    tune.title
  )}Cover-small.png`
  const title = tune.title
  const link_href = getHref(title)

  const out_now = isOutNow(tune)

  return (
    <NavLink
      className={classes}
      id={`tune-${num_of_songs - tune.id + 1}`}
      to={`${link_href}`}>
      <div className={out_now ? 'small-tunes_link-container-link-img' : 'small-tunes_link-container-link-img new-release'}>
        <img src={`${img_src}`} alt={title} />
      </div>
      <div
        className='small-tunes_link-container-link-img-desc'
        id={`desc-${tune.id}`}>
        {tune.description}
      </div>
      <div className='small-tunes_link-container-link-text'>Hear Now</div>
    </NavLink>
  )
}

SmallTuneLink.propTypes = {
  classes: PropTypes.string.isRequired,
  tune: PropTypes.object.isRequired,
  num_of_songs: PropTypes.number.isRequired,
}

export default SmallTuneLink
