import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import YouTube from 'react-youtube'
import { toCamelCase } from '../../../helpers/songInfoFunctions'

const YoutubeEmbed = (props) => {
  const { tune } = props
  const maxTVWidth = 900
  const [screenWidth, setScreenWidth] = useState(
    window.innerWidth < maxTVWidth ? window.innerWidth : maxTVWidth
  )
  const tvDimensions = {
    tvWidth: `${Math.floor(screenWidth * 0.8)}px`,
    tvHeight: `${Math.floor(screenWidth * 0.52)}px`,
    playerWidth: `${Math.floor(screenWidth * 0.53)}px`,
    playerHeight: `${Math.floor(screenWidth * 0.3)}px`,
    playerTop: `${Math.floor(screenWidth * 0.1)}px`,
    playerLeft: `${Math.floor(screenWidth * 0.05)}px`,
    speakerWidth: `${Math.floor(screenWidth * 0.16)}px`,
    speakerTop: `${Math.floor(screenWidth * 0.305)}px`,
    speakerLeft: `${Math.floor(screenWidth * 0.625)}px`,
  }
  const playerWidth = screenWidth * 0.53

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < maxTVWidth) {
        setScreenWidth(window.innerWidth)
      } else {
        setScreenWidth(maxTVWidth)
      }
    })
  })

  const videoIdArr = tune.youtube_embed_code.split('/')
  const videoId = videoIdArr[videoIdArr.length - 1]

  // console.log(screenWidth, playerWidth, window.innerWidth)

  const options = {
    width: `${playerWidth}`,
    height: `${playerWidth * 0.5625}`,
    autoplay: 0,
    cc_load_policy: 0,
    modestbranding: 1,
    fs: 0,
    iv_load_policy: 3,
    loop: 1,
    playsinline: 1,
    rel: 0,
  }

  function playerReady() {
    const player = document.querySelector('.youtube_embed')
    setTimeout(() => {
      player.classList.remove('loading')
    }, 600)
  }

  function speakerAnimationToggle() {
    const speaker = document.querySelector('.television_speaker')
    const player = document.querySelector('.youtube_embed')

    speaker.classList.toggle('playing')
    player.classList.toggle('playing')
  }

  return (
    <div
      className='television'
      style={{
        width: tvDimensions.tvWidth,
        height: tvDimensions.tvHeight,
      }}>
      <div className='television_black-border'></div>
      <div className='television_face'>
        <img src='./assets/images/vintageTelevisionDrawing.svg' alt='' />
      </div>
      {tune.youtube_embed_code !== '' ? (
        <YouTube
          className={'youtube_embed loading'}
          style={{
            width: tvDimensions.playerWidth,
            height: tvDimensions.playerHeight,
            top: tvDimensions.playerTop,
            left: tvDimensions.playerLeft,
          }}
          videoId={videoId}
          opts={options}
          onPlay={speakerAnimationToggle}
          onPause={speakerAnimationToggle}
          onReady={playerReady}
        />
      ) : (
        <img
          style={{
            height: tvDimensions.playerHeight,
            position: 'absolute',
            top: tvDimensions.playerTop,
            left: `${Math.floor(screenWidth * 0.15)}px`,
          }}
          src={`./assets/images/coverArt/${toCamelCase(
            tune.title
          )}Cover-medium.png`}
          alt={`Album Cover for ${tune.title}`}
        />
      )}
      <div className='television_glass'>
        <img src='./assets/images/vintageTelevisionDarkGlass.svg' alt='' />
      </div>
      <div
        className='television_speaker'
        style={{
          width: tvDimensions.speakerWidth,
          top: tvDimensions.speakerTop,
          left: tvDimensions.speakerLeft,
        }}>
        <img src='./assets/images/vintageTelevisionSpeaker.svg' alt='' />
      </div>
    </div>
  )
}

YoutubeEmbed.propTypes = {
  tune: PropTypes.object.isRequired,
}

export default YoutubeEmbed
