import React from 'react'
import PropTypes from 'prop-types'

const PandoraSVG = (props) => {
  const { svg_class_name, fill } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='250'
      height='250'
      className={svg_class_name}
      version='1.1'
      viewBox='0 0 66.146 66.146'>
      <g transform='translate(0 -230.854)'>
        <path
          fill={fill}
          stroke={fill}
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeOpacity='1'
          strokeWidth='0.265'
          d='M5.997 295.834h16.966c2.012.064 3.058-.775 3.117-2.538v-12.473h7.749c16.795-.35 25.916-8.849 26.458-26.458-.353-17.61-12.895-21.908-22.348-22.348H5.86z'></path>
      </g>
    </svg>
  )
}

PandoraSVG.propTypes = {
  svg_class_name: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

export default PandoraSVG
