import React from 'react'
import PropTypes from 'prop-types'

const PauseBtnSVG = (props) => {
    const { bgColor, fgColor } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='200'
      height='200'
      version='1.1'
      viewBox='0 0 52.917 52.917'>
      <g transform='translate(0 -244.083)'>
        <g
          fillOpacity='1'
          strokeDasharray='none'
          strokeLinejoin='miter'
          strokeMiterlimit='4'
          strokeOpacity='1'
          paintOrder='normal'>
          <circle
            cx='26.458'
            cy='270.542'
            r='25.398'
            fill={bgColor}
            stroke={bgColor}
            strokeLinecap='round'
            strokeWidth='1.27'
            opacity='1'></circle>
          <g
            fill={fgColor}
            stroke={fgColor}
            strokeLinecap='butt'
            strokeWidth='1.323'
            transform='translate(.391 .104)'>
            <path
              d='M15.02 256.452H22.012999999999998V284.422H15.02z'
              opacity='1'></path>
            <path d='M30.121 256.452H37.114V284.422H30.121z' opacity='1'></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

PauseBtnSVG.propTypes = {
    bgColor: PropTypes.string.isRequired,
    fgColor: PropTypes.string.isRequired,
}

export default PauseBtnSVG
