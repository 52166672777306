import React from 'react'
import PropTypes from 'prop-types'

import '../../../../styles/modules/global/dist/new-release.css'

import { hexToRGBA } from '../../../../helpers/colorHelpers'

import Player from './Player'
import NewStreamLinks from '../../StreamingLinks/StreamLinks'

const NewRelease = (props) => {
  const { tune } = props

  return (
    <div className='player_container'>
      <Player tune={tune} />
      <div
        className='release'
        style={{
          color: tune.color_scheme.txtColor,
          textShadow: `1px 1px 5px ${hexToRGBA(tune.color_scheme.txtColor, .3)}`,
        }}>
        <span className='release_available'>Available Everywhere</span>
        <span className='release_date'>{tune.release_date}</span>
      </div>
      <div
        className='description'
        style={{
          backgroundColor: tune.color_scheme.flexColor2,
          color: tune.color_scheme.bgColor,
        }}>
        <span className='description-text'>{tune.description}</span>
      </div>
      <NewStreamLinks
        tune={tune}
        fill={tune.color_scheme.flexColor1}
        background={tune.color_scheme.bgColor}
        stream_text_color={tune.color_scheme.txtColor}
      />
    </div>
  )
}

NewRelease.propTypes = {
  tune: PropTypes.object.isRequired,
}

export default NewRelease
