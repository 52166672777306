import React, { useState, useEffect } from 'react'

import { default_color_theme } from '../../../reference/defaults'

import Navbar from '../Navbar/Navbar'
import Headline from '../Headline/Headline'
import SmallTunes from '../../Small/Tunes/SmallTunes'
import BigTunes from '../../Big/Tunes/BigTunes'
import Bio from '../Bio/Bio'
import StreamLinks from '../StreamingLinks/StreamLinks'

const Home = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const breakpoint = 908
  const nav_links = [
    { name: 'Home', href: '#headline' },
    { name: 'Music', href: '#music' },
    { name: 'Stream', href: '#stream' },
    { name: 'About', href: '#bio' },
  ]

  var size

  width < breakpoint ? (size = 'small') : (size = 'big')

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
  })

  document.body.style.backgroundColor = default_color_theme.bgColor

  return (
    <div className='main'>
      <Navbar size={size} nav_links={nav_links} colors={default_color_theme} />
      <Headline size={size} />
      {size === 'small' ? <SmallTunes /> : <BigTunes />}
      <StreamLinks
        container_id='stream'
        fill={default_color_theme.flexColor1}
        background={default_color_theme.bgColor}
        stream_text_color={default_color_theme.flexColor2}
      />
      <Bio />
    </div>
  )
}

export default Home
