import React from 'react'
import PropTypes from 'prop-types'

const BNBigHeader = (props) => {
  const { class_name } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='bn-nested-svg'
      width='1000'
      height='400'
      className={class_name}
      version='1.1'
      viewBox='0 0 264.583 105.833'>
      <defs id='defs2'>
        <clipPath id='clipPath976' clipPathUnits='userSpaceOnUse'>
          <path
            id='rect978'
            fill='none'
            fillOpacity='1'
            stroke='#000'
            strokeDasharray='none'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeMiterlimit='4'
            strokeOpacity='1'
            strokeWidth='0.261'
            d='M118.559 155.676H131.128V179.944H118.559z'
            opacity='1'
            paintOrder='normal'></path>
        </clipPath>
        <clipPath id='clipPath988' clipPathUnits='userSpaceOnUse'>
          <path
            id='rect990'
            fill='none'
            fillOpacity='1'
            stroke='#000'
            strokeDasharray='none'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeMiterlimit='4'
            strokeOpacity='1'
            strokeWidth='0.265'
            d='M124.497 168.724H133.094V181.386H124.497z'
            opacity='1'
            paintOrder='normal'></path>
        </clipPath>
        <clipPath id='clipPath1035' clipPathUnits='userSpaceOnUse'></clipPath>
        <clipPath id='clipPath906' clipPathUnits='userSpaceOnUse'>
          <path
            id='path908'
            fill='none'
            stroke='#000'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeOpacity='1'
            strokeWidth='0.202'
            d='M131.115 185.866v-17.66c-.038-2.443 1.499-5.65 5.372-5.88 4.464.291 5.508 3.077 5.613 5.884v17.706h12.068v-34.91h-36.946v35.114z'></path>
        </clipPath>
        <clipPath id='clipPath923' clipPathUnits='userSpaceOnUse'></clipPath>
        <clipPath id='clipPath959' clipPathUnits='userSpaceOnUse'></clipPath>
        <clipPath id='clipPath993' clipPathUnits='userSpaceOnUse'>
          <circle
            id='circle995'
            cx='22.425'
            cy='336.165'
            r='25.415'
            fill='none'
            fillOpacity='1'
            stroke='red'
            strokeDasharray='none'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeMiterlimit='4'
            strokeOpacity='1'
            strokeWidth='0.466'
            opacity='1'
            paintOrder='normal'></circle>
        </clipPath>
        <clipPath id='clipPath1070' clipPathUnits='userSpaceOnUse'></clipPath>
        <clipPath id='clipPath866' clipPathUnits='userSpaceOnUse'>
          <circle
            id='circle868'
            cx='162.538'
            cy='266.716'
            r='29.675'
            fill='none'
            fillOpacity='1'
            stroke='#0ff'
            strokeDasharray='none'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeMiterlimit='4'
            strokeOpacity='1'
            strokeWidth='0.544'
            opacity='1'
            paintOrder='normal'></circle>
        </clipPath>
        <clipPath id='clipPath972' clipPathUnits='userSpaceOnUse'>
          <path
            id='rect974'
            fill='#f0f'
            fillOpacity='1'
            stroke='#f0f'
            strokeDasharray='none'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeMiterlimit='4'
            strokeOpacity='1'
            strokeWidth='0.265'
            d='M368.156 404.769H391.54200000000003V428.15500000000003H368.156z'
            opacity='1'
            paintOrder='normal'></path>
        </clipPath>
      </defs>
      <g
        id='layer1'
        fillOpacity='1'
        strokeLinecap='butt'
        strokeLinejoin='miter'
        strokeOpacity='1'
        transform='translate(0 -191.167)'>
        <g id='g946'>
          <path
            id='path1023'
            fill='#e85851'
            stroke='#e85851'
            strokeWidth='0.337'
            d='M153.748 232.67c-4.205 1.22-7.996 3.2-11.462 5.783v37.638c-.474 8.86 7.421 20.272 20.14 20.14 9.39-.722 19.618-5.584 20.368-20.367-.343-12.846-9.992-19.598-20.026-20.026-3.04-.046-6.047.613-9.02 2.008zm8.762 34.45a9.216 9.216 0 01.92.046 9.216 9.216 0 01.911.137 9.216 9.216 0 01.893.228 9.216 9.216 0 01.865.316 9.216 9.216 0 01.83.4 9.216 9.216 0 01.785.482 9.216 9.216 0 01.734.558 9.216 9.216 0 01.674.628 9.216 9.216 0 01.608.692 9.216 9.216 0 01.536.75 9.216 9.216 0 01.458.799 9.216 9.216 0 01.377.84 9.216 9.216 0 01.29.875 9.216 9.216 0 01.202.899 9.216 9.216 0 01.11.914 9.216 9.216 0 01.024.652 9.216 9.216 0 01-.046.92 9.216 9.216 0 01-.138.911 9.216 9.216 0 01-.228.893 9.216 9.216 0 01-.316.865 9.216 9.216 0 01-.4.83 9.216 9.216 0 01-.482.785 9.216 9.216 0 01-.557.733 9.216 9.216 0 01-.629.675 9.216 9.216 0 01-.692.608 9.216 9.216 0 01-.75.536 9.216 9.216 0 01-.798.458 9.216 9.216 0 01-.841.376 9.216 9.216 0 01-.874.29 9.216 9.216 0 01-.899.202 9.216 9.216 0 01-.915.111 9.216 9.216 0 01-.652.023 9.216 9.216 0 01-.92-.046 9.216 9.216 0 01-.91-.137 9.216 9.216 0 01-.894-.228 9.216 9.216 0 01-.865-.316 9.216 9.216 0 01-.83-.4 9.216 9.216 0 01-.785-.482 9.216 9.216 0 01-.733-.558 9.216 9.216 0 01-.674-.628 9.216 9.216 0 01-.608-.692 9.216 9.216 0 01-.536-.75 9.216 9.216 0 01-.458-.799 9.216 9.216 0 01-.376-.84 9.216 9.216 0 01-.29-.875 9.216 9.216 0 01-.203-.899 9.216 9.216 0 01-.11-.914 9.216 9.216 0 01-.024-.652 9.216 9.216 0 01.046-.92 9.216 9.216 0 01.138-.911 9.216 9.216 0 01.228-.892 9.216 9.216 0 01.315-.866 9.216 9.216 0 01.401-.83 9.216 9.216 0 01.482-.785 9.216 9.216 0 01.558-.733 9.216 9.216 0 01.627-.674 9.216 9.216 0 01.692-.609 9.216 9.216 0 01.75-.535 9.216 9.216 0 01.799-.458 9.216 9.216 0 01.84-.377 9.216 9.216 0 01.875-.29 9.216 9.216 0 01.9-.202 9.216 9.216 0 01.913-.111 9.216 9.216 0 01.652-.023z'
            clipPath='url(#clipPath866)'
            transform='matrix(.93409 0 0 .93409 -131.792 -29.404)'></path>
          <path
            id='path1025'
            fill='#07a99a'
            stroke='#07a99a'
            strokeDasharray='none'
            strokeMiterlimit='4'
            strokeWidth='0.348'
            d='M47.353 209.35a18.963 18.963 0 00-18.963 18.964 18.963 18.963 0 0018.963 18.963 18.963 18.963 0 0018.963-18.963 18.963 18.963 0 00-18.963-18.964zm0 10.448a8.516 8.516 0 01.85.043 8.516 8.516 0 01.841.127 8.516 8.516 0 01.825.21 8.516 8.516 0 01.8.292 8.516 8.516 0 01.766.37 8.516 8.516 0 01.726.445 8.516 8.516 0 01.678.516 8.516 8.516 0 01.622.58 8.516 8.516 0 01.563.64 8.516 8.516 0 01.495.692 8.516 8.516 0 01.423.738 8.516 8.516 0 01.348.777 8.516 8.516 0 01.268.808 8.516 8.516 0 01.187.83 8.516 8.516 0 01.102.845 8.516 8.516 0 01.022.603 8.516 8.516 0 01-.043.85 8.516 8.516 0 01-.127.842 8.516 8.516 0 01-.211.824 8.516 8.516 0 01-.292.8 8.516 8.516 0 01-.37.766 8.516 8.516 0 01-.445.726 8.516 8.516 0 01-.515.678 8.516 8.516 0 01-.58.623 8.516 8.516 0 01-.64.561 8.516 8.516 0 01-.692.495 8.516 8.516 0 01-.739.424 8.516 8.516 0 01-.777.348 8.516 8.516 0 01-.807.268 8.516 8.516 0 01-.831.186 8.516 8.516 0 01-.845.103 8.516 8.516 0 01-.602.021 8.516 8.516 0 01-.85-.042 8.516 8.516 0 01-.842-.128 8.516 8.516 0 01-.825-.21 8.516 8.516 0 01-.8-.292 8.516 8.516 0 01-.766-.37 8.516 8.516 0 01-.726-.445 8.516 8.516 0 01-.677-.515 8.516 8.516 0 01-.623-.58 8.516 8.516 0 01-.562-.64 8.516 8.516 0 01-.495-.692 8.516 8.516 0 01-.423-.739 8.516 8.516 0 01-.348-.777 8.516 8.516 0 01-.268-.807 8.516 8.516 0 01-.187-.83 8.516 8.516 0 01-.102-.846 8.516 8.516 0 01-.022-.602 8.516 8.516 0 01.043-.85 8.516 8.516 0 01.127-.842 8.516 8.516 0 01.21-.825 8.516 8.516 0 01.292-.8 8.516 8.516 0 01.37-.766 8.516 8.516 0 01.446-.726 8.516 8.516 0 01.514-.677 8.516 8.516 0 01.58-.623 8.516 8.516 0 01.64-.562 8.516 8.516 0 01.693-.495 8.516 8.516 0 01.738-.424 8.516 8.516 0 01.777-.347 8.516 8.516 0 01.808-.269 8.516 8.516 0 01.83-.186 8.516 8.516 0 01.845-.103 8.516 8.516 0 01.603-.021z'
            opacity='1'
            paintOrder='normal'></path>
          <path
            id='path1027'
            fill='#eeefc2'
            stroke='#eeefc2'
            strokeWidth='0.224'
            d='M81.608 214.922c-3.692-2.902-7.447-5.717-13.364-5.51-7.99.01-12.822 4.212-16.671 9.717l17.7 17.7c-2.591.247-4.974-.442-7.07-2.412l-7.368 7.368c3.508 3.384 7.83 5.473 13.409 5.554 7.365-.733 13.316-3.385 16.555-9.715L67.11 219.936c2.64-.292 5.044.378 7.1 2.473z'></path>
          <path
            id='path1029'
            fill='#e85851'
            stroke='#e85851'
            strokeWidth='0.224'
            d='M100.948 214.922c-3.693-2.902-7.447-5.717-13.365-5.51-7.99.01-12.821 4.212-16.67 9.717l17.7 17.7c-2.592.247-4.974-.442-7.071-2.412l-7.368 7.368c3.509 3.384 7.83 5.473 13.41 5.554 7.365-.733 13.316-3.385 16.554-9.715L86.45 219.936c2.64-.292 5.043.378 7.1 2.473z'></path>
          <path
            id='path1031'
            fill='#07a99a'
            stroke='#07a99a'
            strokeWidth='0.337'
            d='M153.748 232.67c-4.205 1.22-7.996 3.2-11.462 5.783v37.638c-.474 8.86 7.421 20.272 20.14 20.14 9.39-.722 19.618-5.584 20.368-20.367-.343-12.846-9.992-19.598-20.026-20.026-3.04-.046-6.047.613-9.02 2.008zm8.762 34.45a9.216 9.216 0 01.92.046 9.216 9.216 0 01.911.137 9.216 9.216 0 01.893.228 9.216 9.216 0 01.865.316 9.216 9.216 0 01.83.4 9.216 9.216 0 01.785.482 9.216 9.216 0 01.734.558 9.216 9.216 0 01.674.628 9.216 9.216 0 01.608.692 9.216 9.216 0 01.536.75 9.216 9.216 0 01.458.799 9.216 9.216 0 01.377.84 9.216 9.216 0 01.29.875 9.216 9.216 0 01.202.899 9.216 9.216 0 01.11.914 9.216 9.216 0 01.024.652 9.216 9.216 0 01-.046.92 9.216 9.216 0 01-.138.911 9.216 9.216 0 01-.228.893 9.216 9.216 0 01-.316.865 9.216 9.216 0 01-.4.83 9.216 9.216 0 01-.482.785 9.216 9.216 0 01-.557.733 9.216 9.216 0 01-.629.675 9.216 9.216 0 01-.692.608 9.216 9.216 0 01-.75.536 9.216 9.216 0 01-.798.458 9.216 9.216 0 01-.841.376 9.216 9.216 0 01-.874.29 9.216 9.216 0 01-.899.202 9.216 9.216 0 01-.915.111 9.216 9.216 0 01-.652.023 9.216 9.216 0 01-.92-.046 9.216 9.216 0 01-.91-.137 9.216 9.216 0 01-.894-.228 9.216 9.216 0 01-.865-.316 9.216 9.216 0 01-.83-.4 9.216 9.216 0 01-.785-.482 9.216 9.216 0 01-.733-.558 9.216 9.216 0 01-.674-.628 9.216 9.216 0 01-.608-.692 9.216 9.216 0 01-.536-.75 9.216 9.216 0 01-.458-.799 9.216 9.216 0 01-.376-.84 9.216 9.216 0 01-.29-.875 9.216 9.216 0 01-.203-.899 9.216 9.216 0 01-.11-.914 9.216 9.216 0 01-.024-.652 9.216 9.216 0 01.046-.92 9.216 9.216 0 01.138-.911 9.216 9.216 0 01.228-.892 9.216 9.216 0 01.315-.866 9.216 9.216 0 01.401-.83 9.216 9.216 0 01.482-.785 9.216 9.216 0 01.558-.733 9.216 9.216 0 01.627-.674 9.216 9.216 0 01.692-.609 9.216 9.216 0 01.75-.535 9.216 9.216 0 01.799-.458 9.216 9.216 0 01.84-.377 9.216 9.216 0 01.875-.29 9.216 9.216 0 01.9-.202 9.216 9.216 0 01.913-.111 9.216 9.216 0 01.652-.023z'
            clipPath='url(#clipPath866)'
            transform='rotate(179.925 132.167 243) scale(.93409)'></path>
          <path
            id='path1035'
            fill='#eeefc2'
            stroke='#eeefc2'
            strokeWidth='0.224'
            d='M159.097 238.988v-10.714c-.484-6.154-2.087-12.104-10.448-16.822v16.859c-.747 6.01-4.046 8.153-8.52 8.52-5.263-.57-8.103-3.405-8.511-8.511V211.37c-6.224 3.667-10.325 8.868-10.448 16.944 1.16 12.155 7.978 17.915 18.964 18.964 3.955-.145 6.494-.952 8.497-2.01v2.01c4.123-1.832 7.513-4.742 10.466-8.291z'></path>
          <path
            id='path1037'
            fill='#e85851'
            stroke='#e85851'
            strokeWidth='0.224'
            d='M167.724 209.412c-10.28.129-18.662 7.671-18.963 18.963.178 11.058 9.002 18.986 18.963 18.964 5.712-.164 10.025-2.2 13.378-5.523l-7.388-7.387c-3.22 3.075-8.87 3.854-12.682-.804h24.915c.51-1.644.764-3.391.74-5.25-.19-12.492-10.198-18.75-18.963-18.963zm0 10.448c.696.012 1.351.096 1.965.24.614.145 1.187.35 1.717.603a8.3 8.3 0 011.463.897c.446.34.847.719 1.206 1.123a8.797 8.797 0 011.616 2.66c.179.466.313.937.4 1.4.088.464.129.92.123 1.359H159.21c.005-.401.047-.829.128-1.27a8.979 8.979 0 01.98-2.713c.249-.447.542-.882.883-1.294a7.983 7.983 0 012.631-2.086 8.572 8.572 0 011.78-.642 9.641 9.641 0 011.014-.19c.351-.046.717-.076 1.097-.087z'></path>
          <path
            id='path1047'
            fill='#07a99a'
            stroke='#07a99a'
            strokeWidth='0.224'
            d='M177.435 214.922c3.692-2.902 7.447-5.717 13.364-5.51 7.99.01 12.822 4.212 16.671 9.717l-17.7 17.7c2.591.247 4.974-.442 7.07-2.412l7.369 7.368c-3.51 3.384-7.83 5.473-13.41 5.554-7.365-.733-13.316-3.385-16.555-9.715l17.689-17.688c-2.64-.292-5.044.378-7.1 2.473z'></path>
        </g>
        <path
          id='path1033'
          fill='#e85851'
          stroke='#e85851'
          strokeWidth='0.224'
          d='M120.724 268.341v10.714c.485 6.154 2.088 12.104 10.448 16.822v-16.859c.747-6.01 4.046-8.153 8.52-8.52 5.264.57 8.104 3.405 8.511 8.512v16.948c6.225-3.667 10.326-8.868 10.448-16.944-1.16-12.155-7.977-17.915-18.963-18.963-3.956.144-6.495.952-8.497 2.01v-2.01c-4.124 1.832-7.513 4.74-10.467 8.29z'></path>
        <path
          id='path1039'
          fill='#07a99a'
          stroke='#07a99a'
          strokeWidth='0.224'
          d='M186.244 287.69v-10.713c-.484-6.154-2.087-12.104-10.447-16.822v16.859c-.747 6.01-4.047 8.153-8.52 8.52-5.264-.57-8.104-3.405-8.512-8.511v-16.949c-6.224 3.667-10.325 8.868-10.447 16.944 1.159 12.155 7.977 17.915 18.963 18.963 3.955-.144 6.494-.951 8.497-2.01v2.01c4.123-1.831 7.513-4.74 10.466-8.29z'></path>
        <g id='g927' fill='#eeefc2' stroke='#eeefc2'>
          <path
            id='path1041'
            strokeWidth='0.224'
            d='M176.082 268.341v10.714c.484 6.154 2.087 12.104 10.448 16.822v-16.859c.747-6.01 4.046-8.153 8.52-8.52 5.264.57 8.104 3.405 8.511 8.512v16.948c6.225-3.667 10.325-8.868 10.448-16.944-1.16-12.155-7.977-17.914-18.963-18.963-3.956.144-6.495.952-8.497 2.01v-2.01c-4.124 1.832-7.513 4.74-10.467 8.29z'></path>
          <path
            id='path923'
            strokeWidth='0.265'
            d='M189.949 259.168c-3.085-.064-4.897-2.558-4.902-4.902-.014-2.762 2.203-4.95 4.973-4.972 1.612.08 3.21.635 5.968 2.218l4.12 2.719v-4.92c2.187-.04 4.913 1.682 4.936 4.937.008 2.586-2.124 4.888-4.92 4.92-.95-.025-2.507-.173-6.025-2.212l-4.115-2.725z'></path>
        </g>
        <path
          id='path1043'
          fill='#e85851'
          stroke='#e85851'
          strokeWidth='0.224'
          d='M223.658 258.115c-10.281.129-18.663 7.671-18.964 18.963.178 11.058 9.003 18.986 18.964 18.964 5.712-.164 10.025-2.2 13.377-5.523l-7.388-7.387c-3.219 3.075-8.869 3.854-12.681-.804h24.914c.51-1.644.765-3.391.741-5.25-.19-12.492-10.198-18.75-18.963-18.963zm0 10.448a9.3 9.3 0 011.964.24c.614.145 1.187.35 1.717.603.53.254 1.019.556 1.464.897.445.34.847.718 1.205 1.123a8.797 8.797 0 011.616 2.66c.18.466.313.937.4 1.4.088.464.13.92.123 1.359h-17.002c.005-.401.047-.829.128-1.27a8.98 8.98 0 01.98-2.713c.248-.447.542-.882.882-1.294a7.983 7.983 0 012.631-2.086 8.573 8.573 0 011.781-.642 9.667 9.667 0 011.013-.19c.352-.046.718-.076 1.098-.087z'></path>
        <path
          id='path1045'
          fill='#07a99a'
          stroke='#07a99a'
          strokeWidth='0.224'
          d='M233.463 263.625c3.692-2.902 7.447-5.717 13.365-5.51 7.99.01 12.821 4.212 16.67 9.717l-17.7 17.7c2.591.247 4.974-.442 7.07-2.412l7.369 7.368c-3.51 3.384-7.83 5.473-13.41 5.554-7.365-.734-13.316-3.385-16.555-9.715l17.689-17.689c-2.64-.291-5.044.38-7.1 2.474z'></path>
      </g>
    </svg>
  )
}

BNBigHeader.propTypes = {
  class_name: PropTypes.string,
}

export default BNBigHeader
