import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import '../../../styles/modules/big/dist/tune-links.css'

import { getHref, toCamelCase, isOutNow } from '../../../helpers/songInfoFunctions'

const TuneLink = (props) => {
  const { classes, tune, num_of_songs } = props

  const img_src = `${process.env.PUBLIC_URL}/assets/images/coverArt/${toCamelCase(
    tune.title
  )}Cover-small.png`
  const title = tune.title
  const link_href = getHref(title)
  const out_now = isOutNow(tune)

  /*********** SHOW DESCRIPTION ***********/
  function showDescription(e) {
    e.preventDefault()
    const desc = document.querySelector(`#desc-${tune.id}`)

    desc.classList.toggle('hide')
  }

  /*********** HIDE DESCRIPTION ***********/
  function hideDescription(e) {
    e.preventDefault()
    const desc = document.querySelector(`#desc-${tune.id}`)

    desc.classList.toggle('hide')
  }

  return (
    <NavLink
      className={classes}
      id={`tune-${num_of_songs - tune.id + 1}`}
      to={`/${link_href}`}
      onMouseOver={showDescription}
      onMouseLeave={hideDescription}
      >
      <div className={out_now ? 'tunes-link-img' : 'tunes-link-img new-release'}>
        <img src={`${img_src}`} alt={title} />
        <div className='tunes-link-img-desc hide' id={`desc-${tune.id}`}>
          {tune.description}
        </div>
      </div>
      <div className='tunes-link-text'>Hear Now</div>
    </NavLink>
  )
}

TuneLink.propTypes = {
  classes: PropTypes.string.isRequired,
  tune: PropTypes.object.isRequired,
  num_of_songs: PropTypes.number.isRequired,
}

export default TuneLink
