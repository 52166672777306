const getHref = (string) => {
  return string.split(' ').join('-').toLowerCase()
}

const toCamelCase = (string) => {
  let stringArr = string.split(' ')
  stringArr[0] = stringArr[0].toLowerCase()
  return stringArr.join('')
}

const isOutNow = (tune) => {
  const date_now = Date.now()
  const release_date = new Date(tune.release_date)

  if (release_date - date_now >= 0) {
    return false
  } else {
    return true
  }
}

module.exports = {
  getHref,
  toCamelCase,
  isOutNow,
}
