const linkInfo = {
    instagram: {
        href: 'https://instagram.com/bossqueznunez',
        img: 'instagram(colorVariant).svg',
        alt: 'Instagram Logo',
        title: 'Instagram',
    },
    bandcamp: {
        href: 'https://bossqueznunez.bandcamp.com',
        img: 'bandcamp(colorVariant).svg',
        alt: 'Bandcamp Logo',
        title: 'Bandcamp',
    },
    youtube: {
        href: 'https://www.youtube.com/channel/UC_WfP_FjGkYKtiBiS9Mswpg',
        img: 'youtube(colorVariant).svg',
        alt: 'Youtube Logo',
        title: 'Youtube',
    },
    spotify: {
        href: 'https://open.spotify.com/artist/43t00eMa7J8P3GogBj1RaQ',
        img: 'spotify(colorVariant).svg',
        alt: 'Spotify Logo',
        title: 'Spotify',
    },
    apple: {
        href: 'https://music.apple.com/us/artist/bossquez-nu%C3%B1ez/1597553796',
        img: 'apple(colorVariant).svg',
        alt: 'Apple Music Logo',
        title: 'Apple',
    },
    amazon: {
        href: 'https://music.amazon.com/artists/B09MMNSLG1/bossquez-nu%C3%B1ez',
        img: 'amazon(colorVariant).svg',
        alt: 'Amazon Logo',
        title: 'Amazon',
    },
    pandora: {
        href: 'https://www.pandora.com/artist/bossquez-nunez/ARKr65kv7x2dctq',
        img: 'pandora(colorVariant).svg',
        alt: 'Pandora Logo', 
        title: 'Pandora',
    },
    deezer: {
        href: 'https://www.deezer.com/en/artist/152652882',
        img: 'deezer(colorVariant).svg',
        alt: 'Deezer Logo',
        title: 'Deezer',
    },
}

module.exports = {
    linkInfo
}