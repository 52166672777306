const tunes = [
  {
    id: 1,
    title: 'Gymnopedie No. 1',
    release_date: '11/14/2021',
    color_scheme: {
      bgColor: '#585147',
      txtColor: '#C5CDBC',
      flexColor1: '#A7BAAB',
      flexColor2: '#99A699',
    },
    description:
      'Performed by Bossquez Nuñez, Gymnopedie No. 1 by composer Erik Satie is widely regarded as one of the most relaxing pieces ever written.',
    hrefs: {
      instagram: '',
      bandcamp: 'https://bossqueznunez.bandcamp.com/track/gymnop-die-no-1',
      youtube: 'https://www.youtube.com/watch?v=Ga-gbVd6qGo',
      spotify: 'https://open.spotify.com/album/2V2iThX7x6qjkFhLmLcrkI',
      apple: 'https://music.apple.com/us/album/gymnopedie-no-1/1599136704?i=1599136706',
      amazon: 'https://www.amazon.com/music/player/albums/B09N7WG8FW',
      pandora: 'https://www.pandora.com/artist/bossquez-nunez/gymnopedie-no-1-single/gymnopedie-no-1/TRqjXgd3P6rlkjK',
      deezer: 'https://www.deezer.com/en/album/278877302',
    },
    spotify_embed_code:
      'https://open.spotify.com/embed/track/70VOOKfSB4JohsIyaZn8rr?utm_source=generator',
    youtube_embed_code: 'https://www.youtube-nocookie.com/embed/2vx8s8hW2pw',
  },
  {
    id: 2,
    title: 'God Rest Ye Merry Gentlemen',
    release_date: '12/17/2021',
    color_scheme: {
      bgColor: '#37242A',
      txtColor: '#7C939A',
      flexColor1: '#AA6968',
      flexColor2: '#6B7184',
    },
    description:
      'May God rest ye merry! Bossquez Nuñez bringeth ye tidings of comfort and joy this Christmas season. He also bringeth ye a tasty synth solo.',
    hrefs: {
      instagram: '',
      bandcamp: 'https://bossqueznunez.bandcamp.com/track/god-rest-ye-merry-gentlemen',
      youtube: 'https://www.youtube.com/watch?v=PTSbswCBkkU',
      spotify: 'https://open.spotify.com/album/2WSjv4SvoWmgokx0xjTZMD',
      apple: 'https://music.apple.com/us/album/god-rest-ye-merry-gentlemen-single/1597553825',
      amazon: 'https://www.amazon.com/music/player/albums/B09MML52DK',
      pandora: 'https://www.pandora.com/artist/bossquez-nunez/god-rest-ye-merry-gentlemen-single/god-rest-ye-merry-gentlemen/TRgzbzXwK2cX2vw',
      deezer: 'https://www.deezer.com/en/album/275900512',
    },
    spotify_embed_code:
      'https://open.spotify.com/embed/track/78SCZsXejwT5A1zXRkvygp?utm_source=generator',
    youtube_embed_code: 'https://www.youtube-nocookie.com/embed/5XYYADnT2GI',
  },
  {
    id: 3,
    title: 'Or Whatever',
    release_date: '3/22/2022',
    color_scheme: {
      bgColor: '#645B55',
      txtColor: '#C2B2AA',
      flexColor1: '#ABA499',
      flexColor2: '#A79CA4',
    },
    description:
      'Instrumental with driving drums and bass, warbly synth pads, and a lovely lo-fi lead on top. Contemplative and melancholic.',
    hrefs: {
      instagram: '',
      bandcamp: 'https://bossqueznunez.bandcamp.com/track/or-whatever',
      youtube: 'https://www.youtube.com/watch?v=baDKMOIMe28&list=OLAK5uy_krXJAoC0LO5IaVn306CHWHyWuaOvTYBS8',
      spotify: 'https://open.spotify.com/album/7JWjPCLTdJKq6uhekYky8H',
      apple: 'https://music.apple.com/us/album/or-whatever/1609515230?i=1609515231',
      amazon: 'https://www.amazon.com/music/player/albums/B09S3KHT2B',
      pandora: 'https://www.pandora.com/artist/bossquez-nunez/or-whatever-single/or-whatever/TRz952f4cwPn34P',
      deezer: 'https://www.deezer.com/en/album/294446872',
    },
    spotify_embed_code:
      'https://open.spotify.com/embed/track/5fMc9Ayt7Y2KonckuSNmir?utm_source=generator',
    youtube_embed_code: 'https://www.youtube-nocookie.com/embed/baDKMOIMe28',
  },
  {
    id: 4,
    title: 'Fourteen Dollars',
    release_date: '5/17/2022',
    color_scheme: {
      bgColor: '#755554',
      txtColor: '#CF904D',
      flexColor1: '#E6BE8A',
      flexColor2: '#E4E7E4',
    },
    description:
      'The perfect song for any occasion having at all to do with fourteen US dollars; be it receiving, giving, spending, or what have you.',
    hrefs: {
      instagram: '',
      bandcamp: 'https://bossqueznunez.bandcamp.com/track/fourteen-dollars',
      youtube: 'https://www.youtube.com/watch?v=4HdoH9TAL2k',
      spotify: 'https://open.spotify.com/album/4pyuKsUb41c5XQzoLW7a9T',
      apple: 'https://music.apple.com/us/album/fourteen-dollars-single/1621748664',
      amazon: 'https://www.amazon.com/music/player/albums/B09Z7G1NXV',
      pandora: 'https://www.pandora.com/artist/bossquez-nunez/fourteen-dollars-single/fourteen-dollars/TRp2ctPgjXKX4wP',
      deezer: 'https://www.deezer.com/en/album/343147017',
    },
    spotify_embed_code:
      'https://open.spotify.com/embed/track/7aizSoCOOV34SeHXTISQrp?utm_source=generator',
    youtube_embed_code: 'https://www.youtube-nocookie.com/embed/4HdoH9TAL2k',
  },
  {
    id: 5,
    title: 'For The Love Of God',
    release_date: '7/5/2022',
    color_scheme: {
      bgColor: '#5B3B2B',
      txtColor: '#8CC4A4',
      flexColor1: '#EB8C20',
      flexColor2: '#8CC4A4',
    },
    description: "It's just the blues, for the love of God.",
    hrefs: {
      instagram: '',
      bandcamp: 'https://bossqueznunez.bandcamp.com/track/for-the-love-of-god',
      youtube: 'https://www.youtube.com/watch?v=otJWEiZh0Zw',
      spotify: 'https://open.spotify.com/album/5dgK8LyQlHvWpCLYeq7vvI',
      apple: 'https://music.apple.com/us/album/for-the-love-of-god-single/1631394620',
      amazon: 'https://music.amazon.com/albums/B0B4BLPG85',
      pandora: 'https://www.pandora.com/artist/bossquez-nunez/for-the-love-of-god-single/for-the-love-of-god/TRb6Zlhnw5PrwtZ',
      deezer: 'https://www.deezer.com/en/album/328597947',
    },
    spotify_embed_code:
      'https://open.spotify.com/embed/track/68M75tu6vbnuGLPFHMEUid?utm_source=generator',
    youtube_embed_code: 'https://www.youtube-nocookie.com/embed/otJWEiZh0Zw',
  },
  {
    id: 6,
    title: 'Heebie Jeebies',
    release_date: '8/2/2022',
    color_scheme: {
      bgColor: '#542E32',
      txtColor: '#A8AAA9',
      flexColor1: '#78706F',
      flexColor2: '#C45362',
    },
    description:
      'Written by Boyd Atkins, made popular by Louis Armstrong, performed here by Bossquez Nuñez, this is Heebie Jeebies.',
    hrefs: {
      instagram: '',
      bandcamp: 'https://bossqueznunez.bandcamp.com/track/heebie-jeebies',
      youtube: 'https://www.youtube.com/watch?v=WHAYMCEJMq4&list=OLAK5uy_kypn2lwusqBKBfKZI1J2lrrOPblJ3aYtY',
      spotify: 'https://open.spotify.com/album/7cXrEPZuQZxc6WpTf1EnJA',
      apple: 'https://music.apple.com/us/album/heebie-jeebies-single/1634840479',
      amazon: 'https://music.amazon.com/albums/B0B6DJ79GV',
      pandora: 'https://www.pandora.com/artist/bossquez-nunez/heebie-jeebies-single/heebie-jeebies/TRZml2ddK79rtzV',
      deezer: 'https://www.deezer.com/en/album/335722337',
    },
    spotify_embed_code:
      'https://open.spotify.com/embed/track/2RYDRxZQ1HmJa3xyWpWJ6Q?utm_source=generator',
    youtube_embed_code: 'https://www.youtube-nocookie.com/embed/WHAYMCEJMq4',
  },
  {
    id: 7,
    title: 'Chaussettes De Luxe',
    release_date: '10/25/2022',
    color_scheme: {
      bgColor: '#DCE1DF',
      txtColor: '#3E383B',
      flexColor1: '#83161E',
      flexColor2: '#7A6D68',
    },
    description:
      'This song is an exceptionally elegant pair of socks one might find after taking an escalator to an upper floor.',
    hrefs: {
      instagram: '',
      bandcamp: 'https://bossqueznunez.bandcamp.com/track/chaussettes-de-luxe',
      youtube: 'https://www.youtube.com/watch?v=VSm6KJIG8Hgs',
      spotify: 'https://open.spotify.com/album/3Ajprt0uk6FNMoLF482B7D',
      apple: 'https://music.apple.com/us/album/chaussettes-de-luxe-single/1649661212',
      amazon: 'https://music.amazon.com/albums/B0BJ16R53X',
      pandora: 'https://www.pandora.com/artist/bossquez-nunez/chaussettes-de-luxe-single/AL54b7m7PVZcdqK',
      deezer: 'https://www.deezer.com/en/album/366137977',
    },
    spotify_embed_code:
      'https://open.spotify.com/embed/album/3Ajprt0uk6FNMoLF482B7D?utm_source=generator',
    youtube_embed_code: 'https://www.youtube.com/embed/VSm6KJIG8Hg',
  },
  {
    id: 8,
    title: 'O Little Town Of Bethlehem',
    release_date: '12/16/2022',
    color_scheme: {
      bgColor: '#1E152A',
      txtColor: '#F1E4E8',
      flexColor1: '#3C5E46',
      flexColor2: '#E8AC3A',
    },
    description:
      'Bossquez Nuñez gives this classic American Christmas carol a joyous African folk vibe.',
    hrefs: {
      instagram: '',
      bandcamp: 'https://bossqueznunez.bandcamp.com/track/o-little-town-of-bethlehem',
      youtube: 'https://www.youtube.com/watch?v=0W6ekpHg4Sg',
      spotify: 'https://open.spotify.com/album/3wMeLfR2SuCscxuU6CcRDM',
      apple: 'https://music.apple.com/us/album/o-little-town-of-bethlehem-single/1658994162',
      amazon: 'https://www.amazon.com/music/player/albums/B0BPJPFB87',
      pandora: 'https://www.pandora.com/artist/bossquez-nunez-holiday/o-little-town-of-bethlehem/ALvJv77vcrcdzhV',
      deezer: 'https://www.deezer.com/en/album/385434967',
    },
    spotify_embed_code:
      '',
    youtube_embed_code: 'https://www.youtube.com/embed/0W6ekpHg4Sg',
  },
  {
    id: 9,
    title: 'Rejoicing In The Name',
    release_date: '08/08/2023',
    // color_scheme: {
    //   bgColor: '#6B8B9E',
    //   txtColor: '#221E22',
    //   flexColor1: '#EFA9AE',
    //   flexColor2: '#934439',
    // },
    color_scheme: {
      bgColor: '#221E22',
      txtColor: '#934439',
      flexColor1: '#6B8B9E',
      flexColor2: '#EFA9AE',
    },
    description:
      'A soulful, meditative solo electric bass piece written and performed by Bossquez Nuñez.',
    hrefs: {
      instagram: '',
      bandcamp: 'https://bossqueznunez.bandcamp.com/track/rejoicing-in-the-name',
      youtube: 'https://www.youtube.com/watch?v=XSh9xyrjthE',
      spotify: 'https://open.spotify.com/album/1xLJ7OFSutEuZJZ3BoGWeq',
      apple: 'https://music.apple.com/us/album/rejoicing-in-the-name-single/1697415580',
      amazon: 'https://www.amazon.com/music/player/albums/B0CBT1J4YV',
      pandora: 'https://www.pandora.com/artist/bossquez-nunez/rejoicing-in-the-name/ALvVmVzcX22pJZV',
      deezer: 'https://www.deezer.com/en/album/464753075',
    },
    spotify_embed_code:
      '',
    youtube_embed_code: 'https://www.youtube.com/embed/XSh9xyrjthE',
  }
]

module.exports = { tunes }
