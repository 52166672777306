import React from 'react'
import PropTypes from 'prop-types'

const Burger = (props) => {
  const { colors, handleClick } = props
  return (
    <div className='burger' onClick={handleClick}>
      <div className='burger-bar' style={{backgroundColor: colors.bgColor}}></div>
      <div className='burger-bar' style={{backgroundColor: colors.bgColor}}></div>
      <div className='burger-bar' style={{backgroundColor: colors.bgColor}}></div>
    </div>
  )
}

Burger.propTypes = {
  colors: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default Burger
