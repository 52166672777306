import React from 'react'
import PropTypes from 'prop-types'

const BandcampSVG = (props) => {
  const { svg_class_name, fill, background } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='250'
      height='250'
      className={svg_class_name}
      version='1.1'
      viewBox='0 0 66.146 66.146'>
      <g transform='translate(0 -230.854)'>
        <g
          fillOpacity='1'
          strokeDasharray='none'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='4'
          strokeOpacity='1'
          paintOrder='normal'>
          <circle
            cx='33.073'
            cy='263.927'
            r='29.559'
            fill={fill}
            stroke={fill}
            strokeWidth='5.895'
            opacity='1'></circle>
          <g
            fill={background}
            stroke={background}
            strokeWidth='6.615'
            transform='rotate(-30 110.214 81.24) scale(.80432)'>
            <path
              d='M-37.03 249.753l-9.647 5.57-9.648 5.57v-22.28l9.648 5.57z'
              opacity='1'></path>
            <path
              d='M-82.167 249.753l9.648-5.57 9.648-5.57v22.28l-9.648-5.57z'
              opacity='1'></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

BandcampSVG.propTypes = {
  svg_class_name: PropTypes.string,
  fill: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
}

export default BandcampSVG
