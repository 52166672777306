import React, { useState, useEffect, useRef } from 'react'

import '../../../styles/modules/small/dist/small-tunes.css'

import { tunes } from '../../../reference/tunes'

import SmallTuneLink from '../../Small/Tunes/SmallTuneLink'

const SmallTunes = (props) => {
  /*********** VARIABLES ***********/
  const [isPlaying, setIsPlaying] = useState(true)
  const linkWidth = 16

  const tunes_reversed = [...tunes].reverse()

  var slideTime = 3000

  /***** PERSISTENT VARIABLES *****/
  const firstTime = useRef(true)

  var currentTranslate = useRef(0)
  var playPauseBtnSrc = useRef('pause')

  useEffect(() => {
    if (isPlaying) {
      const linkInterval = setInterval(incrementLinks, slideTime)

      return () => clearInterval(linkInterval)
    }
  })

  function handlePrev(e) {
    decrementLinks()
  }
  function handleNext(e) {
    incrementLinks()
  }
  function handlePlayPause(e) {
    const playPauseBtn = document.querySelector('#pause-small').firstChild
    const prevBtn = document.querySelector('#prev')
    const nextBtn = document.querySelector('#next')
    const buttonsDiv = document.querySelector('#buttons-div')

    playPauseBtn.parentElement.classList.toggle('long')
    buttonsDiv.classList.toggle('paused')

    if (playPauseBtnSrc.current === 'pause') {
      // PAUSE
      setIsPlaying(false)
      playPauseBtnSrc.current = 'play'
    } else {
      // PLAY
      setIsPlaying(true)
      firstTime.current = true
      playPauseBtnSrc.current = 'pause'
    }

    prevBtn.classList.toggle('hide')
    nextBtn.classList.toggle('hide')

    playPauseBtn.src = `./assets/images/${playPauseBtnSrc.current}btn-small-tunes.png`
  }

  function incrementLinks() {
    const linkContainer = document.querySelector('.small-tunes_link-container')

    if (currentTranslate.current === -((tunes.length - 1) * linkWidth)) {
      currentTranslate.current = 0
    } else {
      currentTranslate.current -= linkWidth
    }

    linkContainer.style.transform = `translateX(${currentTranslate.current}rem)`
  }

  function decrementLinks() {
    const linkContainer = document.querySelector('.small-tunes_link-container')

    if (currentTranslate.current === 0) {
      currentTranslate.current = -((tunes.length - 1) * linkWidth)
    } else {
      currentTranslate.current += linkWidth
    }

    linkContainer.style.transform = `translateX(${currentTranslate.current}rem)`
  }

  return (
    <div className='small-tunes' id='music'>
      <div className='link_window'>
        <div className='small-tunes_link-container'>
          {tunes_reversed.map((tune, idx) => {
            let classes = 'small-tunes_link-container-link'
            return (
              <SmallTuneLink
                key={tunes.length - tune.id}
                classes={classes}
                tune={tune}
                num_of_songs={tunes.length}
              />
            )
          })}
        </div>
      </div>
      <div className='small-tunes_buttons paused' id='buttons-div'>
        <button
          className='small-tunes_buttons-btn hide'
          id='prev'
          onClick={handlePrev}>
          Prev
        </button>
        <button
          className='small-tunes_buttons-btn pause long'
          id='pause-small'
          onClick={handlePlayPause}>
          <img
            src={`./assets/images/${playPauseBtnSrc.current}btn-small-tunes.png`}
            alt=''
          />
        </button>
        <button
          className='small-tunes_buttons-btn hide'
          id='next'
          onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  )
}

export default SmallTunes
