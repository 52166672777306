import React from 'react'
import PropTypes from 'prop-types'

import { lightColor } from '../../helpers/colorHelpers'

function BNLogo(props) {
  const { colors } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='bn-nested-svg'
      width='250'
      height='250'
      className='navbar_logo_svg'
      version='1.1'
      viewBox='0 0 66.146 66.146'>
      <defs id='defs2'>
        <clipPath id='clipPath976' clipPathUnits='userSpaceOnUse'>
          <path
            id='rect978'
            fill='none'
            fillOpacity='1'
            stroke='#000'
            strokeDasharray='none'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeMiterlimit='4'
            strokeOpacity='1'
            strokeWidth='0.261'
            d='M118.559 155.676H131.128V179.944H118.559z'
            opacity='1'
            paintOrder='normal'></path>
        </clipPath>
        <clipPath id='clipPath988' clipPathUnits='userSpaceOnUse'>
          <path
            id='rect990'
            fill='none'
            fillOpacity='1'
            stroke='#000'
            strokeDasharray='none'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeMiterlimit='4'
            strokeOpacity='1'
            strokeWidth='0.265'
            d='M124.497 168.724H133.094V181.386H124.497z'
            opacity='1'
            paintOrder='normal'></path>
        </clipPath>
        <clipPath id='clipPath1035' clipPathUnits='userSpaceOnUse'></clipPath>
        <clipPath id='clipPath906' clipPathUnits='userSpaceOnUse'>
          <path
            id='path908'
            fill='none'
            stroke='#000'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeOpacity='1'
            strokeWidth='0.202'
            d='M131.115 185.866v-17.66c-.038-2.443 1.499-5.65 5.372-5.88 4.464.291 5.508 3.077 5.613 5.884v17.706h12.068v-34.91h-36.946v35.114z'></path>
        </clipPath>
        <clipPath id='clipPath923' clipPathUnits='userSpaceOnUse'></clipPath>
        <clipPath id='clipPath959' clipPathUnits='userSpaceOnUse'></clipPath>
        <clipPath id='clipPath993' clipPathUnits='userSpaceOnUse'>
          <circle
            id='circle995'
            cx='22.425'
            cy='336.165'
            r='25.415'
            fill='none'
            fillOpacity='1'
            stroke='red'
            strokeDasharray='none'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeMiterlimit='4'
            strokeOpacity='1'
            strokeWidth='0.466'
            opacity='1'
            paintOrder='normal'></circle>
        </clipPath>
        <clipPath id='clipPath1070' clipPathUnits='userSpaceOnUse'></clipPath>
        <clipPath id='clipPath866' clipPathUnits='userSpaceOnUse'>
          <circle
            id='circle868'
            cx='162.538'
            cy='266.716'
            r='29.675'
            fill='none'
            fillOpacity='1'
            stroke='#0ff'
            strokeDasharray='none'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeMiterlimit='4'
            strokeOpacity='1'
            strokeWidth='0.544'
            opacity='1'
            paintOrder='normal'></circle>
        </clipPath>
      </defs>
      <g id='layer1' transform='translate(0 -230.854)'>
        <g
          id='g873'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeOpacity='1'
          transform='translate(-.008 -.048)'>
          <circle
            id='bn-nested-circle'
            cx='33.081'
            cy='263.975'
            r='32.699'
            fill={colors ? colors.flexColor1 : '#ff0'}
            fillOpacity='1'
            stroke='none'
            strokeDasharray='none'
            strokeMiterlimit='4'
            strokeWidth='0.599'
            opacity='1'
            paintOrder='normal'></circle>
          <path
            id='bn-nested-b'
            fill={colors ? colors.bgColor : 'green'}
            stroke={colors ? colors.bgColor : 'green'}
            strokeWidth='0.337'
            d='M153.748 232.67c-4.205 1.22-7.996 3.2-11.462 5.783v37.638c-.474 8.86 7.421 20.272 20.14 20.14 9.39-.722 19.618-5.584 20.368-20.367-.343-12.846-9.992-19.598-20.026-20.026-3.04-.046-6.047.613-9.02 2.008zm8.762 34.45a9.216 9.216 0 01.92.046 9.216 9.216 0 01.911.137 9.216 9.216 0 01.893.228 9.216 9.216 0 01.865.316 9.216 9.216 0 01.83.4 9.216 9.216 0 01.785.482 9.216 9.216 0 01.734.558 9.216 9.216 0 01.674.628 9.216 9.216 0 01.608.692 9.216 9.216 0 01.536.75 9.216 9.216 0 01.458.799 9.216 9.216 0 01.377.84 9.216 9.216 0 01.29.875 9.216 9.216 0 01.202.899 9.216 9.216 0 01.11.914 9.216 9.216 0 01.024.652 9.216 9.216 0 01-.046.92 9.216 9.216 0 01-.138.911 9.216 9.216 0 01-.228.893 9.216 9.216 0 01-.316.865 9.216 9.216 0 01-.4.83 9.216 9.216 0 01-.482.785 9.216 9.216 0 01-.557.733 9.216 9.216 0 01-.629.675 9.216 9.216 0 01-.692.608 9.216 9.216 0 01-.75.536 9.216 9.216 0 01-.798.458 9.216 9.216 0 01-.841.376 9.216 9.216 0 01-.874.29 9.216 9.216 0 01-.899.202 9.216 9.216 0 01-.915.111 9.216 9.216 0 01-.652.023 9.216 9.216 0 01-.92-.046 9.216 9.216 0 01-.91-.137 9.216 9.216 0 01-.894-.228 9.216 9.216 0 01-.865-.316 9.216 9.216 0 01-.83-.4 9.216 9.216 0 01-.785-.482 9.216 9.216 0 01-.733-.558 9.216 9.216 0 01-.674-.628 9.216 9.216 0 01-.608-.692 9.216 9.216 0 01-.536-.75 9.216 9.216 0 01-.458-.799 9.216 9.216 0 01-.376-.84 9.216 9.216 0 01-.29-.875 9.216 9.216 0 01-.203-.899 9.216 9.216 0 01-.11-.914 9.216 9.216 0 01-.024-.652 9.216 9.216 0 01.046-.92 9.216 9.216 0 01.138-.911 9.216 9.216 0 01.228-.892 9.216 9.216 0 01.315-.866 9.216 9.216 0 01.401-.83 9.216 9.216 0 01.482-.785 9.216 9.216 0 01.558-.733 9.216 9.216 0 01.627-.674 9.216 9.216 0 01.692-.609 9.216 9.216 0 01.75-.535 9.216 9.216 0 01.799-.458 9.216 9.216 0 01.84-.377 9.216 9.216 0 01.875-.29 9.216 9.216 0 01.9-.202 9.216 9.216 0 01.913-.111 9.216 9.216 0 01.652-.023z'
            clipPath='url(#clipPath866)'
            transform='matrix(1.1019 0 0 1.1019 -146.019 -29.918)'></path>
          <path
            id='bn-nested-n'
            fill={colors ? lightColor(colors.txtColor) : 'red'}
            stroke={colors ? lightColor(colors.txtColor) : 'red'}
            strokeWidth='0.292'
            d='M17.079 289.9c1.983 2.104 4.175 3.645 6.277 4.546l-.386-20.06c.372-4.633 3.732-9.924 10.3-9.92 6.242.355 9.881 5.555 9.881 9.87v19.68c2.145-.908 4.429-2.528 6.274-4.44l.052-15.294c.247-10.321-9.215-16.432-16.213-16.213-2.727-.016-5.704.48-9.836 3.335v-3.112c-2.26 1.5-4.562 3.314-6.35 6.554z'></path>
        </g>
      </g>
    </svg>
  )
}

BNLogo.propTypes = {
  colors: PropTypes.object,
}

export default BNLogo
