import React from 'react'
import PropTypes from 'prop-types'

const AppleSVG = (props) => {
  const { svg_class_name, fill } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='250'
      height='250'
      className={svg_class_name}
      version='1.1'
      viewBox='0 0 66.146 66.146'>
      <g transform='translate(0 -230.854)'>
        <g
          fill={fill}
          stroke={fill}
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeOpacity='1'
          strokeWidth='0.265'
          transform='matrix(.6427 0 0 .6427 -49.087 102.805)'>
          <path d='M85.125 254.638c1.043-9.053 2.104-26.202 26.326-30.335 5.341-.051 11.016 3.69 17.24 4.81 6.337-1.283 11.634-4.936 20.044-5.078 6.62 1.29 13.954 2.287 19.11 10.29-7.219 4.59-10.943 10.992-11.359 19.11 1.061 6.11 1.203 13.88 14.032 20.847-4.583 13.226-13.685 27.598-23.252 26.994-4.203.977-9.68-3.607-17.24-4.009-8.03.584-12.408 4.467-17.238 4.143-8.694.176-17.044-11.475-24.188-28.33-2.786-6.962-3.153-12.713-3.475-18.442z'></path>
          <path d='M134.04 207.987c5.52-6.515 10.29-6.602 15.213-7.985.455 5.426-.663 10.89-5.48 16.442-4.668 5.347-9.762 8.607-15.686 7.796-.253-4.883.47-10 5.953-16.253z'></path>
        </g>
      </g>
    </svg>
  )
}

AppleSVG.propTypes = {
  svg_class_name: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

export default AppleSVG
