import React from 'react'
import PropTypes from 'prop-types'

const AmazonSVG = (props) => {
  const { svg_class_name, fill } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='amazon-svg'
      width='250'
      height='250'
      className={svg_class_name}
      version='1.1'
      viewBox='0 0 66.146 66.146'>
      <g id='layer1' transform='translate(0 -230.854)'>
        <g
          id='g851'
          fill={fill}
          strokeWidth='0.265'
          transform='matrix(.63596 0 0 .63596 -59.86 103.233)'>
          <g
            id='amazon-a'
            fillOpacity='1'
            stroke='none'
            aria-label='a'
            fontFamily='OfficinaSanITCBoo'
            fontSize='10.583'
            fontStretch='normal'
            fontStyle='normal'
            fontWeight='bold'
            transform='translate(439.52 -3486.602) scale(13.78926)'
            style={{ lineHeight: '1.25' }}
            fontVariant='normal'
            letterSpacing='0'
            wordSpacing='0'>
            <path
              id='path824'
              strokeWidth='0.265'
              d='M-20.607 270.533q-.793 0-1.143.19-.338.191-.338.657 0 .307.19.519.201.211.487.211.423 0 .804-.412zm0-1.206q0-.455-.148-.625-.148-.169-.55-.169-.476 0-1.185.275-.064.032-.117.032-.074 0-.106-.106l-.222-.635q-.021-.063-.021-.116 0-.085.159-.17.17-.084.423-.158.265-.074.582-.117.328-.042.646-.042 1.28 0 1.65.815.085.17.117.37.032.201.032.508v2.16q0 .348.053.613.053.254.19.53.032.063.032.116 0 .074-.085.105l-.825.329q-.053.031-.127.031-.148 0-.318-.624-.317.328-.635.466-.307.137-.72.137-.73 0-1.163-.434-.424-.434-.424-1.164 0-1.408 1.577-1.704.212-.053.476-.063.265-.022.71-.032z'></path>
          </g>
          <g
            id='g844'
            stroke='#000'
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeOpacity='1'
            transform='translate(0 -.267)'>
            <path
              id='amazon-path1'
              d='M98.935 279.14c24.515 15.272 51.288 22.478 84.572 6.426 2.444-.19 2.268 1.017.945 2.835-8.967 6.204-14.108 13.463-39.593 15.12-27.467-1.4-35.253-13.093-47.247-22.585-.647-1.687-.776-2.855 1.323-1.795z'></path>
            <path
              id='amazon-path2'
              d='M176.137 281.975c-.015-2.622 4.356-3.326 8.22-4.252 3.1-.094 5.511-1.004 10.49 1.134.603 2.709-.07 6.017-1.607 9.733l-4.63 6.993c-2.147 1.877-2.474 1.07-1.984-.945 1.554-4.267 4.672-9.054 3.118-12.285-1.984-1.737-6.636-.956-10.772-.661-1.29.381-2.3.531-2.835.283z'></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

AmazonSVG.propTypes = {
  svg_class_name: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

export default AmazonSVG
