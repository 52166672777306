import React from 'react'
import PropTypes from 'prop-types'

import PlayBtnSVG from '../../../SVGs/PlayBtnSVG'
import PauseBtnSVG from '../../../SVGs/PauseBtnSVG'
import { hexToRGBA } from '../../../../helpers/colorHelpers'

const Audio = (props) => {
  const { tune } = props

  const audio_src = `./assets/audio/${tune.title}.mp3`

  var playing = false

  function playSong() {
    const audio = document.querySelector('#audio')

    playing ? audio.pause() : audio.play()
    playing = !playing
    playOrPauseBtn()
  }

  // Determine whether to display the play or pause button
  function playOrPauseBtn() {
    const playBtn = document.querySelector('#play-button-div')
    const pauseBtn = document.querySelector('#pause-button-div')

    !playing
      ? (playBtn.style.display = 'inline')
      : (playBtn.style.display = 'none')
    !playing
      ? (pauseBtn.style.display = 'none')
      : (pauseBtn.style.display = 'inline')
  }

  function updateTime() {
    const audio = document.querySelector('#audio')
    const audioCurrentTime = document.querySelector(
      '.player_transport_time-current'
    )
    const progressFg = document.querySelector('.player_transport_status-bar')
    const slider = document.querySelector('#slider')

    progressFg.style.width = `${Math.round(
      (audio.currentTime / audio.duration) * 100
    )}%`
    audioCurrentTime.innerText = formatTime(audio.currentTime)
    slider.value = audio.currentTime
  }

  function endSong() {
    const progressFg = document.querySelector('.player_transport_status-bar')
    const audioCurrentTime = document.querySelector(
      '.player_transport_time-current'
    )
    const slider = document.querySelector('#slider')

    playing = false
    playOrPauseBtn()
    progressFg.style.width = '0%'
    audioCurrentTime.innerText = formatTime(0)
    slider.value = 0
  }

  function sliderChange() {
    const audio = document.querySelector('#audio')
    const slider = document.querySelector('#slider')

    audio.currentTime = slider.value
  }

  function setSlider() {
    const audio = document.querySelector('#audio')
    const audioDuration = document.querySelector(
      '.player_transport_time-duration'
    )
    const slider = document.querySelector('#slider')

    audioDuration.innerText = formatTime(audio.duration)
    slider.max = Math.round(audio.duration)
  }

  function formatTime(seconds) {
    let time = new Date(0)
    time.setSeconds(seconds)
    let timeString = time.toISOString().substr(15, 4)

    return timeString
  }

  return (
    <div className='player_transport'>
      <audio
        className='player_transport_audio'
        id='audio'
        type='audio/mp3'
        src={audio_src}
        preload='metadata'
        onLoadedMetadata={setSlider}
        onTimeUpdate={updateTime}
        onEnded={endSong}></audio>
      <div className='player_transport_play'>
        <div id='play-button-div' onClick={playSong}>
          <PlayBtnSVG
            bgColor={tune.color_scheme.flexColor1}
            fgColor={tune.color_scheme.bgColor}
          />
        </div>
        <div
          id='pause-button-div'
          style={{ display: 'none' }}
          onClick={playSong}>
          <PauseBtnSVG
            bgColor={tune.color_scheme.flexColor1}
            fgColor={tune.color_scheme.bgColor}
          />
        </div>
      </div>
      <div className='player_transport_status'>
        <input
          className='player_transport_status-slider'
          id='slider'
          type='range'
          min='0'
          max='0'
          onChange={sliderChange}
        />
        <div
          className='player_transport_status-bg'
          style={{ backgroundColor: tune.color_scheme.flexColor1 }}></div>
        <div
          className='player_transport_status-bar'
          style={{ backgroundColor: tune.color_scheme.bgColor }}></div>
      </div>
      <div className='player_transport_time' style={{ color: tune.color_scheme.bgColor, textShadow: `1px 1px 5px ${hexToRGBA(tune.color_scheme.bgColor, .3)}` }}>
        <div className='player_transport_time-current'>0:00</div>/
        <div className='player_transport_time-duration'></div>
      </div>
    </div>
  )
}

Audio.propTypes = {
  tune: PropTypes.object.isRequired,
}

export default Audio
