import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { toCamelCase } from '../../../helpers/songInfoFunctions'

import '../../../styles/modules/global/dist/showcase.css'

import { hexToRGBA } from '../../../helpers/colorHelpers'

import YoutubeEmbed from './YoutubeEmbed'

import NewStreamLinks from '../StreamingLinks/StreamLinks'

const Showcase = (props) => {
  const { tune } = props
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth)
    })
  })

  const album_art_src = `./assets/images/coverArt/${toCamelCase(
    tune.title
  )}Cover-medium.png`

  return (
    <div className='showcase'>
      <YoutubeEmbed tune={tune} />
      <div
        className='showcase_main'
        style={{
          backgroundColor: hexToRGBA(tune.color_scheme.txtColor, 0.6),
        }}>
        <img src={album_art_src} className='showcase_main_img' alt='' />
        <div
          className='showcase_main_desc'
          style={{
            color: tune.color_scheme.bgColor,
          }}>
          <p>{tune.description}</p>
          <hr
            id='desc-hr'
            style={{
              backgroundColor: hexToRGBA(tune.color_scheme.bgColor, 0.4),
            }}
          />
          <span className='showcase_main_desc-date' id='release-date'>
            Available everywhere {tune.release_date}
          </span>
        </div>
      </div>
      <NewStreamLinks
        tune={tune}
        container_class={screenWidth < 1400 ? 'stream' : 'stream in-grid'}
        fill={tune.color_scheme.flexColor1}
        background={tune.color_scheme.bgColor}
        stream_text_color={tune.color_scheme.flexColor2}
      />
    </div>
  )
}

Showcase.propTypes = {
  tune: PropTypes.object.isRequired,
}

export default Showcase
