function lightColor(txt_color) {
  let txt_color_arr = txt_color.split('').splice(1, txt_color.length - 1)
  let new_arr = []
  txt_color_arr.forEach((char) => {
    char = char.toUpperCase()
    switch (char) {
      case '0':
        char = '3'
        break
      case '1':
        char = '4'
        break
      case '2':
        char = '5'
        break
      case '3':
        char = '6'
        break
      case '4':
        char = '7'
        break
      case '5':
        char = '8'
        break
      case '6':
        char = '9'
        break
      case '7':
        char = 'A'
        break
      case '8':
        char = 'B'
        break
      case '9':
        char = 'C'
        break
      case 'A':
        char = 'D'
        break
      case 'B':
        char = 'E'
        break
      case 'C':
        char = 'F'
        break
      case 'D':
        char = 'F'
        break
      case 'E':
        char = 'F'
        break
      case 'F':
        break
      default:
    }

    new_arr.push(char)
  })

  let new_str = '#' + new_arr.join('')
  return new_str
}

// Convert hex color to rgba
function hexToRGBA(hex, opacity) {
  let c = '0x' + hex.substring(1)
  return `rgb(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
    ', '
  )}, ${opacity})`
}

module.exports = {
  lightColor,
  hexToRGBA,
}
