import React from 'react'
import PropTypes from 'prop-types'

const StreamLink = (props) => {
  const { container_class, site, title, fill, svg_component } = props

  return (
    <a href={site} className={`${container_class}-link`}>
      {svg_component}
      <div
        style={{ color: `${fill}` }}
        className={`${container_class}-link-label`}
        id={`${title}-title`}>
        {title}
      </div>
    </a>
  )
}

StreamLink.propTypes = {
  container_class: PropTypes.string.isRequired,
  site: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  svg_component: PropTypes.element.isRequired,
}

export default StreamLink
