import React from 'react'
import PropTypes from 'prop-types'

import '../../../styles/modules/global/dist/headline.css'

import BNBigHeader from '../../SVGs/BNBigHeader'
import BNSmallHeader from '../../SVGs/BNSmallHeader'

const Headline = (props) => {
  const { size } = props
  return (
    <div className='headline' id='headline'>
      {size === 'small' ? (
        <BNSmallHeader class_name='headline_svg' />
      ) : (
        <BNBigHeader class_name='headline_svg' />
      )}
    </div>
  )
}

Headline.propTypes = {
  size: PropTypes.string.isRequired,
}

export default Headline
