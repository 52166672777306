import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import '../../../styles/modules/global/dist/navbar.css'

import BNLogo from '../../SVGs/BNLogo'

import BigMenu from './BigMenu'
import Burger from './Burger'
import NavMenu from './NavMenu'

const Navbar = (props) => {
  const { size, nav_links, colors } = props

  function toggleActive(e) {
    e.preventDefault()
    const links = document.querySelectorAll('.navbar_links-link')
    const hrefSplit = e.target.href.split('/')
    const href = hrefSplit[hrefSplit.length - 1]
    console.log('hello', href)

    if (href !== '') {
      const targetEl = document.querySelector(href)
      targetEl.scrollIntoView({ block: 'start', behavior: 'smooth' })
    } else {
      window.location = `/`
    }

    links.forEach((link) => {
      link.classList.remove('active-link')
      link.style.color = colors.txtColor
    })

    e.target.parentElement.classList.add('active-link')
    e.target.parentElement.style.color = colors.bgColor
  }

  function toggleMenu() {
    const menu = document.querySelector('#nav-menu')
    const burgers = document.querySelector('.burger')

    burgers.classList.toggle('open')

    if (menu.classList.contains('open')) {
      menu.classList.remove('open')
      menu.classList.add('closed')
    } else {
      menu.classList.remove('closed')
      menu.classList.add('open')
    }
  }

  function handleLinkClick(e) {    
    const links = document.querySelectorAll('.menu_links_container-link')
    const link = e.target
    const hrefSplit = e.target.href.split('/')
    const href = hrefSplit[hrefSplit.length - 1]

    toggleMenu()

    if (href !== 'home') {
      const targetEl = document.querySelector(href)
      targetEl.scrollIntoView({ block: 'start', behavior: 'smooth' })
    } else {
      window.location = '/'
    }

    links.forEach((link) => link.classList.remove('active-link'))

    link.classList.add('active-link')
  }

  return (
    <div
      className='navbar'
      style={{ backgroundColor: colors.flexColor2, color: colors.txtColor }}>
      <NavLink to='/' className='navbar_logo'>
        <BNLogo colors={colors} />
      </NavLink>
      {size === 'small' ? (
        <div className='navbar_small'>
          <Burger
            class_name_prefix={'navbar_small'}
            colors={colors}
            handleClick={toggleMenu}
          />
          <NavMenu nav_links={nav_links} handleLinkClick={handleLinkClick} colors={colors} />
        </div>
      ) : (
        <BigMenu
          nav_links={nav_links}
          colors={colors}
          toggleActive={toggleActive}
        />
      )}
    </div>
  )
}

Navbar.propTypes = {
  size: PropTypes.string.isRequired,
  nav_links: PropTypes.array.isRequired,
  colors: PropTypes.object.isRequired,
}

export default Navbar
