import React from 'react'
import PropTypes from 'prop-types'

const InstagramSVG = (props) => {
  const { svg_class_name, fill, background } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='250'
      height='250'
      className={svg_class_name}
      version='1.1'
      viewBox='0 0 66.146 66.146'>
      <g
        fillOpacity='1'
        strokeDasharray='none'
        strokeLinecap='butt'
        strokeLinejoin='miter'
        strokeMiterlimit='4'
        strokeOpacity='1'
        paintOrder='normal'
        transform='translate(0 -230.854)'>
        <path
          fill={background}
          stroke={background}
          strokeWidth='5.777'
          d='M9.125 240.357H56.17V287.402H9.125z'
          opacity='1'></path>
        <g stroke={fill}>
          <rect
            width='57.305'
            height='57.305'
            x='4.42'
            y='235.274'
            fill='none'
            strokeWidth='6.795'
            opacity='1'
            rx='10.986'
            ry='12.471'></rect>
          <circle
            cx='33.073'
            cy='263.927'
            r='13.884'
            fill='none'
            strokeWidth='5.683'
            opacity='1'></circle>
          <circle
            cx='50.082'
            cy='246.918'
            r='2.326'
            fill={fill}
            strokeWidth='1.962'
            opacity='1'></circle>
        </g>
      </g>
    </svg>
  )
}

InstagramSVG.propTypes = {
  svg_class_name: PropTypes.string,
  fill: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
}

export default InstagramSVG
