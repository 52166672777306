import React from 'react'

import '../../../styles/modules/big/dist/tunes.css'

import { tunes } from '../../../reference/tunes'

import TuneLink from './TuneLink'

const BigTunes = (props) => {
  /*********** EVENT HANDLERS ***********/
  /********** PREVIOUS BUTTON **********/
  function handlePrev() {
    const prevBtn = document.querySelector('#prev')
    const nextBtn = document.querySelector('#next')
    const dots = document.querySelectorAll('.tunes_pages-dot')
    const activeDots = [...dots].filter(dot => dot.classList.contains('active'))
    const dotToBeDeactivated = dots[[...dots].indexOf(activeDots[0]) - 1]
    const dotToBeActivated = activeDots[2]
    const firstLink = document.querySelector('.first')
    const secondLink = document.querySelector('.second')
    const thirdLink = document.querySelector('.third')

    // Get next link div
    const divToBeShown = document.querySelector(
      `#tune-${parseInt(firstLink.id.split('-')[1]) - 1}`
    )

    // Hide third div
    thirdLink.classList.add('moveRight')    
    thirdLink.classList.remove('third')
    setTimeout(() => {
      thirdLink.classList.toggle('hidden')
      thirdLink.classList.remove('moveRight')
    }, 500)

    // Move first and second links
    secondLink.classList.remove('second')
    secondLink.classList.add('third')
    firstLink.classList.remove('first')
    firstLink.classList.add('second')
    
    // Show next link
    divToBeShown.classList.remove('hidden')
    divToBeShown.classList.add('first')
    
    // Cycle dots
    dotToBeDeactivated.classList.toggle('active')    
    dotToBeActivated.classList.toggle('active')

    // Enable Next Btn if not at end
    if (parseInt(thirdLink.id.split('-')[1]) === tunes.length) {
      nextBtn.classList.remove('disabled')
      nextBtn.disabled = false
    }

    // Disable Prev Btn if at beginning
    if (parseInt(divToBeShown.id.split('-')[1]) === 1) {
      prevBtn.classList.add('disabled')
      prevBtn.disabled = true
    }
  }

  /************ NEXT BUTTON ************/
  function handleNext() {
    const nextBtn = document.querySelector('#next')
    const prevBtn = document.querySelector('#prev')
    const dots = document.querySelectorAll('.tunes_pages-dot')
    const activeDots = [...dots].filter(dot => dot.classList.contains('active'))
    const dotToBeActivated = dots[[...dots].indexOf(activeDots[2]) + 1]
    const dotToBeDeactivated = activeDots[0]
    const firstLink = document.querySelector('.first')
    const secondLink = document.querySelector('.second')
    const thirdLink = document.querySelector('.third')

    // Get next link div
    const divToBeShown = document.querySelector(
      `#tune-${parseInt(thirdLink.id.split('-')[1]) + 1}`
    )

    // Hide first div
    firstLink.classList.add('moveLeft')    
    firstLink.classList.remove('first')
    setTimeout(() => {
      firstLink.classList.toggle('hidden')
      firstLink.classList.remove('moveLeft')
    }, 500)

    // Move second and third links
    secondLink.classList.remove('second')
    secondLink.classList.add('first')
    thirdLink.classList.remove('third')
    thirdLink.classList.add('second')
    
    // Show next link
    divToBeShown.classList.remove('hidden')
    divToBeShown.classList.add('third')
    
    // Cycle dots
    dotToBeDeactivated.classList.toggle('active')    
    dotToBeActivated.classList.toggle('active')

    // Enable Prev Btn if not at beginning
    if (parseInt(firstLink.id.split('-')[1]) === 1) {
      prevBtn.classList.remove('disabled')
      prevBtn.disabled = false
      prevBtn.addEventListener('click', handlePrev)
    }

    // Disable Next Btn if at end
    if (parseInt(thirdLink.id.split('-')[1]) === tunes.length - 1) {
      nextBtn.classList.add('disabled')
      nextBtn.disabled = 'disabled'
    }
  }

  /********** RENDER COMPONENT **********/
  return (
    <div className='tunes' >
      <div className='tunes_container' id='music'>
        {tunes.map((tune, idx) => {
          let classes
          if (idx >= tunes.length - 3) {
            if (idx === tunes.length - 1) {
              classes = 'tunes-link first'
            } else if (idx === tunes.length - 2) {
              classes = 'tunes-link second'
            } else {
              classes = 'tunes-link third'
            }
          } else {
            classes = 'tunes-link hidden'
          }
          return (
            <TuneLink
              key={tunes.length - tune.id}
              classes={classes}
              tune={tune}
              num_of_songs={tunes.length}
            />
          )
        })}
      </div>
      <div className='tunes_pages'>
        <button
          className='tunes_pages-btn disabled'
          id='prev'
          onClick={handlePrev}
          disabled={true}>
          Prev
        </button>
        {tunes.map((tune, idx) => {
          if (idx >= 0 && idx <= 2) {
            return (
              <div
                key={tune.id}
                className='tunes_pages-dot active'
                id={`dot-${idx + 1}`}></div>
            )
          } else {
            return (
              <div
                key={tune.id}
                className='tunes_pages-dot'
                id={`dot-${idx + 1}`}></div>
            )
          }
        })}
        <button className='tunes_pages-btn' id='next' onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  )
}

export default BigTunes
