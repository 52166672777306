import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Showcase from './Showcase'
import NewRelease from './NewReleases/NewRelease'
import Navbar from '../Navbar/Navbar'

import { isOutNow } from '../../../helpers/songInfoFunctions'

const TunePage = (props) => {
  const { tune } = props

  const out_now = isOutNow(tune)

  const [width, setWidth] = useState(window.innerWidth)
  const breakpoint = 908
  const nav_links = [{ name: 'Home', href: `/` }]

  var size

  width < breakpoint ? (size = 'small') : (size = 'big')

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
    window.scrollTo(0, 0)
  })

  document.body.style.backgroundColor = tune.color_scheme.bgColor

  return (
    <div>
      <Navbar size={size} nav_links={nav_links} colors={tune.color_scheme} />
      {out_now ? <Showcase tune={tune} /> : <NewRelease tune={tune} />}
    </div>
  )
}

TunePage.propTypes = {
  tune: PropTypes.object.isRequired,
}

export default TunePage
