import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import '../../../styles/modules/global/dist/stream.css'

import InstagramSVG from '../../SVGs/InstagramSVG'
import BandcampSVG from '../../SVGs/BandcampSVG'
import YoutubeSVG from '../../SVGs/YoutubeSVG'
import SpotifySVG from '../../SVGs/SpotifySVG'
import AppleSVG from '../../SVGs/AppleSVG'
import AmazonSVG from '../../SVGs/AmazonSVG'
import PandoraSVG from '../../SVGs/PandoraSVG'
import DeezerSVG from '../../SVGs/DeezerSVG'

import StreamLink from './StreamLink'

import { linkInfo } from '../../../reference/streamingLinks'

import { breakpoint } from '../../../reference/global_vars'

const NewStreamLinks = (props) => {
  const {
    tune,
    container_class,
    container_id,
    fill,
    background,
    stream_text_color,
  } = props
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const svg_class_name = 'stream_links-link-svg'

  const svg_components = [
    <InstagramSVG
      svg_class_name={svg_class_name}
      fill={fill}
      background={background}
    />,
    <BandcampSVG
      svg_class_name={svg_class_name}
      fill={fill}
      background={background}
    />,
    <YoutubeSVG
      svg_class_name={svg_class_name}
      fill={fill}
      background={background}
    />,
    <SpotifySVG
      svg_class_name={svg_class_name}
      fill={fill}
      background={background}
    />,
    <AppleSVG svg_class_name={svg_class_name} fill={fill} />,
    <AmazonSVG svg_class_name={svg_class_name} fill={fill} />,
    <PandoraSVG svg_class_name={svg_class_name} fill={fill} />,
    <DeezerSVG svg_class_name={svg_class_name} fill={fill} />,
  ]

  var marginTop
  var scrollMarginTop

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth)
    })
  })

  if (tune) {
    marginTop = '5rem'
    scrollMarginTop = ''
  } else if (screenWidth < breakpoint) {
    marginTop = '5rem'
    scrollMarginTop = '8rem'
  } else {
    marginTop = '31.25rem'
    scrollMarginTop = '10rem'
  }

  return (
    <div
      className={container_class ? container_class : 'stream'}
      id={container_id}
      style={{ marginTop: marginTop, scrollMarginTop: scrollMarginTop }}>
      <div className='stream_links'>
        {Object.keys(linkInfo).map((link, idx) => {
          let href = ''
          if (tune) {
            href = tune.hrefs[link]
          }

          return (
            <StreamLink
              key={`link-${idx}`}
              container_class={'stream_links'}
              site={href !== '' ? href : linkInfo[link].href}
              fill={fill}
              title={link}
              svg_component={svg_components[idx]}
            />
          )
        })}
      </div>
      <div style={{ color: stream_text_color }} className='stream_text'>
        Streaming Links
      </div>
    </div>
  )
}

NewStreamLinks.propTypes = {
  tune: PropTypes.object,
  container_class: PropTypes.string,
  svg_class_name: PropTypes.string,
  fill: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  stream_text_color: PropTypes.string,
}

export default NewStreamLinks
