import React from 'react'
import PropTypes from 'prop-types'

const YoutubeSVG = (props) => {
  const { svg_class_name, fill, background } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='250'
      height='250'
      className={svg_class_name}
      version='1.1'
      viewBox='0 0 66.146 66.146'>
      <g transform='translate(0 -230.854)'>
        <g
          fillOpacity='1'
          strokeDasharray='none'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='4'
          strokeOpacity='1'
          paintOrder='normal'
          transform='matrix(.76006 0 0 .76006 80.131 77.333)'>
          <rect
            width='81.034'
            height='50.082'
            x='-102.432'
            y='220.46'
            fill={fill}
            stroke={fill}
            strokeWidth='5.292'
            opacity='1'
            ry='10.016'></rect>
          <path
            fill={background}
            stroke={background}
            strokeWidth='5.958'
            d='M-55.717 245.5l-7.687 4.439-7.687 4.437V236.625l7.687 4.438z'
            opacity='1'></path>
        </g>
      </g>
    </svg>
  )
}

YoutubeSVG.propTypes = {
  svg_class_name: PropTypes.string,
  fill: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
}

export default YoutubeSVG
