import './App.css'
import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

import { tunes } from './reference/tunes'

import Home from './components/Global/Home/Home'
import TunePage from './components/Global/TunePages/TunePage'
import Blog from './components/Blog/Blog'

function App() {
  useEffect(() => {
    document.title = 'Bossquez Nuñez'
  }, [])

  function buildURL(title) {
    return title.split(' ').join('-').toLowerCase()
  }

  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Home />} />
        {tunes.map((tune, idx) => {
          return (
            <Route
              key={tune.id}
              path={buildURL(tune.title)}
              element={<TunePage tune={tune} />}
            />
          )
        })}
        {/* <Route
              path='/blog'
              element={<Blog />}
            /> */}
      </Routes>
    </div>
  )
}

export default App
