import React from 'react'
import PropTypes from 'prop-types'

const SpotifySVG = (props) => {
  const { svg_class_name, fill, background } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='250'
      height='250'
      className={svg_class_name}
      version='1.1'
      viewBox='0 0 66.146 66.146'>
      <g transform='translate(0 -230.854)'>
        <g strokeLinecap='butt' strokeLinejoin='miter' strokeOpacity='1'>
          <circle
            cx='33.073'
            cy='263.927'
            r='32.026'
            fill={fill}
            fillOpacity='1'
            stroke={fill}
            strokeDasharray='none'
            strokeMiterlimit='4'
            strokeWidth='1.338'
            opacity='1'
            paintOrder='normal'></circle>
          <g
            fill={background}
            stroke={background}
            transform='matrix(.57346 0 0 .57346 -44.305 110.63)'>
            <path
              strokeWidth='0.265'
              d='M94.967 249.658c.14-1.806 1.012-4.219 3.968-5.48 8.635-1.658 17.327-3.14 26.459-3.307 15.907.614 31.584 2.9 46.49 10.772 1.806 1.477 3.404 3.018 2.93 5.197-.698 4.663-2.93 4.978-5.197 5.197-.82.06-1.846-.496-2.835-.945-19.919-9.496-41.156-11.48-63.595-6.614-1.105.31-2.084.524-2.834.567-1.443.116-5.046-.842-5.386-5.387z'></path>
            <path
              strokeWidth='0.223'
              d='M99.123 268.18c.119-1.525.855-3.562 3.35-4.627 7.29-1.399 14.627-2.65 22.335-2.791 13.428.518 26.662 2.448 39.245 9.093 1.525 1.247 2.873 2.548 2.473 4.387-.589 3.937-2.474 4.202-4.387 4.387-.692.051-1.558-.419-2.393-.797-16.815-8.016-34.742-9.692-53.683-5.584-.934.261-1.76.442-2.393.479-1.218.098-4.26-.711-4.547-4.547z'></path>
            <path
              strokeWidth='0.189'
              d='M101.779 284.942c.1-1.293.724-3.02 2.84-3.923 6.182-1.186 12.404-2.248 18.94-2.367 11.388.44 22.61 2.076 33.28 7.71 1.293 1.058 2.437 2.162 2.098 3.721-.5 3.338-2.098 3.563-3.72 3.72-.588.044-1.322-.355-2.03-.676-14.259-6.798-29.46-8.218-45.523-4.735-.792.222-1.493.375-2.03.406-1.032.083-3.612-.603-3.855-3.856z'></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

SpotifySVG.propTypes = {
  svg_class_name: PropTypes.string,
  fill: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
}

export default SpotifySVG
