import React from 'react'
import PropTypes from 'prop-types'

const DeezerSVG = (props) => {
  const { svg_class_name, fill } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='250'
      height='250'
      className={svg_class_name}
      version='1.1'
      viewBox='0 0 66.146 66.146'>
      <g transform='translate(0 -230.854)'>
        <g
          fill={fill}
          fillOpacity='1'
          stroke={fill}
          strokeDasharray='none'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='4'
          strokeOpacity='1'
          strokeWidth='4.22'
          paintOrder='normal'>
          <path d='M19.682 279.592H29.494V283.873H19.682z' opacity='1'></path>
          <path d='M19.682 255.852H29.494V260.133H19.682z' opacity='1'></path>
          <path d='M19.682 267.722H29.494V272.003H19.682z' opacity='1'></path>
          <path
            d='M36.653 279.592H46.464999999999996V283.873H36.653z'
            opacity='1'></path>
          <path
            d='M36.653 267.722H46.464999999999996V272.003H36.653z'
            opacity='1'></path>
          <path
            d='M53.623 279.592H63.434999999999995V283.873H53.623z'
            opacity='1'></path>
          <path
            d='M53.623 243.982H63.434999999999995V248.263H53.623z'
            opacity='1'></path>
          <path
            d='M53.623 255.852H63.434999999999995V260.133H53.623z'
            opacity='1'></path>
          <path
            d='M53.623 267.722H63.434999999999995V272.003H53.623z'
            opacity='1'></path>
          <path d='M2.711 279.592H12.523V283.873H2.711z' opacity='1'></path>
        </g>
      </g>
    </svg>
  )
}

DeezerSVG.propTypes = {
  svg_class_name: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

export default DeezerSVG
